import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { Viewtimeoffrequestcomponent } from '../../leavemanagement/viewtimeoffrequest/viewtimeoffrequestcomponent'; 
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import { constants } from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import { LoginService } from '../../../shared/services/loginservice';
import { PerformanceMangemantService } from '../../../shared/services/performancereviewservice';
import {Router} from 'aurelia-router';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';


@inject(DialogController,JwtService,LeavemanagementService,PerformanceMangemantService,ValidationControllerFactory,DialogService,Router,LoginService)
export class ManagerViewcomponent{
    pageSize = config.grid_PazeSize;
    isLoading = false;
   FavoritesExist = false;
   sitemapId=constants.mytimeoffdetailssitemapid;
   controllerFactory;

    constructor(controller,JwtService,LeavemanagementService,PerformanceMangemantService,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
        this.performaceService =PerformanceMangemantService;
    }
   
   async attached(){
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        } 
       
        this.PeriodID =1;
        this.performaceService.GetViewApplicabilityForKRA(this.PeriodID).then(data => {
            this.filteredEmployeesSummery = data.listLocations;
            this.applicabilitykra=data;
            this.isLoading = false;
            console.log(this.filteredEmployeesSummery );
        });

    }
}