import {inject, bindingMode, bindable} from 'aurelia-framework';
import flatpickr from "flatpickr";
import {JwtService} from '../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import {constants} from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import {PerformanceMangemantService} from "../../../shared/services/performancereviewservice";
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";

@inject(DialogController, JwtService, DialogService, PerformanceMangemantService, Router, LoginService, LeavemanagementService,ValidationControllerFactory)
export class MygoalsandkrasheetComponent {

    pageSize = config.grid_PazeSize;
    searchTerm = '';
    search = '';
    FromDate = null;
    ToDate = null;
    isLoading = false;
    FavoritesExist = false;
    Successmessages = false;
    defaultToDate;
    dateDifferenceError = false;


    sitemapId = constants.mykrasheetsitemapid;
    statusOptions = [
        {label: 'All', value: 'All', checked: false},
        {label: 'Published', value: 'PUB', checked: true},
        {label: 'Pending Acceptance', value: 'PAC', checked: true},
        // { label: 'Pending Approval', value: 'PEA', checked: false },
        // { label: 'Approved', value: 'APP', checked: false },

    ];

    constructor(controller, JwtService, DialogService, PerformanceMangemantService, router, loginservice, LeavemanagementService ,controllerFactory) {
        this.controller = controller;
        this.controllerFactory = controllerFactory.createForCurrentScope();

        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.router = router;
        this.loginservice = loginservice;
        this.performancereviewservice = PerformanceMangemantService;
        this.leavemanagementService = LeavemanagementService;

        ValidationRules.customRule(
            'dateError',
            () =>{
                if(this.dateDifferenceError == true){
                    return false;
                }else{
                    return true;
                }
            },
            'From date should be less than to date.'
        );
        ValidationRules
            .ensure('To')
            .satisfiesRule('dateError')
            .on(this);

    }

    activate(data) {
        if (data && data.message == 'true') {
            this.message = 'Accepted successfully'; // Display 'Success!' message in HTML
            setTimeout(() => {
                this.clearMessage();
            }, 10000);
        } else {
            // If the received message is not 'true' or if there's no data, clear the message immediately
            this.clearMessage();
        }
    }

    clearMessage() {
        this.message = null; // Function to clear the message
    }


    async attached() {
        this.leavemanagementService.GetPersonFavoriteItems(this.empid, this.sitemapId)
            .then(data => {
                this.FavoritesExist = data;
            });
        this.addToRecentItems();

        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstDateOfYear = new Date(currentYear, 0, 1); // Month is 0-based index (0 for January)
        this.FDate = formatDateWithLeadingZero(firstDateOfYear);
        this.From = this.FDate;

        const lastDateOfYear = new Date(currentYear, 11, 31); // Month is 0-based index (11 for December)
        this.TDate = formatDateWithLeadingZero(lastDateOfYear);
        this.To = this.TDate;

        function formatDateWithLeadingZero(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
            const year = date.getFullYear();
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMonth = month.toString().padStart(2, '0');
            return `${formattedDay}-${formattedMonth}-${year}`;
        }

        flatpickr(this.datePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: firstDateOfYear,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: function (selectedDates, dateStr, instance) {
                this.fromDate = dateStr;
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });
        flatpickr(this.datePickerss, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: lastDateOfYear,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens: false,
            onClose: function (selectedDates, dateStr, instance) {
                this.toDate = dateStr;
            }
        });
        this.calendarIconEnd.addEventListener('click', () => {
            this.datePickerss._flatpickr.open();
        });

        const selectedStatusList = this.statusOptions
            .filter(statusOptions => statusOptions.checked)
            .map(statusOptions => statusOptions.value);
        const timeoff = {
            EmployeeId: this.empid,
            StatusList: selectedStatusList,
            From: this.From,
            To: this.To
        };

        this.performancereviewservice.PerformanceList(timeoff).then(data => {
            this.PerformanceList = data.EmployeeCategoryList;
            this.defaultToDate = this.PerformanceList.To;
            this.defaultFromDate = this.PerformanceList.From;

        });
    }

    active() {
        const searchButton = document.getElementById('search-button');
        searchButton.addEventListener('click', handleSearch);
    }

    selectAllChanged(event) {
        const isChecked = this.statusOptions[0].checked;
        this.statusOptions.forEach((status, index) => {
            if (index !== 0) {
                status.checked = isChecked;
            }
        });
    }

    handleCheckboxChange(event, status) {
        if (status.value === 'All') {
            this.statusOptions.forEach((statusOption, index) => {
                if (index !== 0) {
                    statusOption.checked = false;
                }
            });
        } else {
            const allChecked = this.statusOptions.slice(1).every(statusOption => statusOption.checked);
            this.statusOptions[0].checked = allChecked;
        }

        //status.checked = event.target.checked;
    }

    addToFavourites() {
        this.leavemanagementService.AddToFavourites(this.empid, this.sitemapId).then(data => {
            window.location.reload();
        });
    }

    addToRecentItems() {
        this.leavemanagementService.AddToRecentItems(this.empid, this.sitemapId).then(data => {

        });
    }

    Reset() {
        this.attached();
        this.statusOptions.forEach((status, index) => {
            if (index === 1 || index === 2) {
                status.checked = true; // Select 'Pending approval' and 'Pending with me'
            } else {
                status.checked = false; // Deselect other statuses
            }
        });

        this.selectedStatusList = this.statusOptions.filter(status => status.checked);


    }

    exportData() {
        const data = this.PerformanceList;
        const filename = 'PerformanceList';
        this.exportToExcel(data, filename);
    }

    exportToExcel(data, filename) {
        const headers = {
            Reviewperiodfrom: 'Review period from',
            Reviewperiodto: 'Review period to',
            Reviewtype: 'Review type',
            Status: 'Status',


        };
        const filteredData = data.map(Performance => {
            return {
                Reviewperiodfrom: Performance.ReviewFromText,
                Reviewperiodto: Performance.ReviewToText,
                Reviewtype: Performance.ReviewTypeName,
                Status: Performance.StatusCodeText,

            };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(Performance => Object.values(Performance));
        const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode;
        clickedDiv.classList.toggle('filter-open');
        const divs = document.querySelectorAll('.filter-open');
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove('filter-open');
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove('filter-open');
                });
                document.removeEventListener('click', handleOutsideClick);
            }
        };
        if (clickedDiv.classList.contains('filter-open')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }

    KraShowDialog(ActionName, RecordStatusCode, PeriodID, KRAPerformanceReviewID, ReviewTypeID, ReviewFromText, ReviewToText, ViewID) {
        const approveData = {
            ActionName: ActionName,
            RecordStatusCode: RecordStatusCode,
            PeriodID: PeriodID,
            KRAPerformanceReviewID: KRAPerformanceReviewID,
            ReviewTypeID: ReviewTypeID,
            ReviewFromText: ReviewFromText,
            ReviewToText: ReviewToText,
            ViewID: ViewID
        };
        const queryString = new URLSearchParams(approveData).toString();
        const performancereviewURL = `/#/MyKRADetails?${queryString}`;
        window.location.href = performancereviewURL;
    }

    ViewKraShowDialog(ActionName, RecordStatusCode, PeriodID, KRAPerformanceReviewID, ReviewTypeID, ReviewFromText, ReviewToText, ViewID) {
        const approveData = {
            ActionName: ActionName,
            RecordStatusCode: RecordStatusCode,
            PeriodID: PeriodID,
            KRAPerformanceReviewID: KRAPerformanceReviewID,
            ReviewTypeID: ReviewTypeID,
            ReviewFromText: ReviewFromText,
            ReviewToText: ReviewToText,
            ViewID: ViewID
        };
        const queryString = new URLSearchParams(approveData).toString();
        const performancereviewURL = `/#/viewMyKRADetails?${queryString}`;
        window.location.href = performancereviewURL;
    }

    resetStatusDropdown() {
        this.statusOptions.forEach((status, index) => {
            if (index === 1 || index === 2) {
                status.checked = true; // Select 'Pending approval' and 'Pending with me'
            } else {
                status.checked = false; // Deselect other statuses
            }
        });

        this.selectedStatusList = this.statusOptions.filter(status => status.checked);

    }
    calculateDateDifference() {
        if (this.From && this.To) {
            const fromParts = this.From.split('-');
            const toParts = this.To.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);

            const today = new Date(); // Get today's date
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            if (fromDate >= toDate || fromDate > today) {
                this.dateDifferenceError = true;
            } else {
                this.dateDifferenceError = false;
            }
        } else {
            this.dateDifferenceError = null;
        }
    }


    Search() {
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    const filterOpenElements = document.querySelectorAll('.filter-open');
                    filterOpenElements.forEach(element => element.classList.remove('filter-open'));
                    const selectedStatusList = this.statusOptions
                        .filter(status => status.checked)
                        .map(status => status.value);

                    const finalToDate = this.To ? this.To : this.defaultToDate;
                    const finalFromDate = this.From ? this.From : this.defaultFromDate;
                    const selectedRadio = document.querySelector('input[name="employees"]:checked');
                    this.selectedValue = selectedRadio ? selectedRadio.value : '';
                    const timeoff = {
                        EmployeeId: this.empid,
                        Fromdate: finalFromDate,
                        Todate: finalToDate,
                        StatusList: selectedStatusList.includes('All') ? ['All'] : selectedStatusList.filter(status => status !== 'All'),

                    };
                    this.isLoading = true;

                    this.performancereviewservice.PerformanceList(timeoff).then(data => {
                        this.PerformanceList = data.EmployeeCategoryList;
                        this.isLoading = false;

                    });
                } });

    }

    get selectedStatuses() {
        return this.statusOptions
            .filter(status => status.checked);
    }

    removeFilter(Allstatus, status) {
        if (status.label === 'All') {
            this.statusOptions.forEach(option => {
                option.checked = false;
            });
        } else {
            status.checked = false;
        }
    }


}